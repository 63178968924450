import { combineReducers } from "redux";
import { configureStore } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import userReducer from "../features/user/userSlice";
import cloudReducer from "../features/cloud/cloudSlice";
import instanceReducer from "../features/instance/instanceSlice";
import selectionReducer from "../features/selection/selectionSlice";
import settingsReducer from "../features/settings/settingsSlice";

// Configuration for redux-persist
const persistConfig = {
  key: "root",
  storage,
  whitelist: ["user", "instance", "settings"],
};

// Combine reducers
const rootReducer = combineReducers({
  user: userReducer,
  cloud: cloudReducer,
  instance: instanceReducer,
  selection: selectionReducer,
  settings: settingsReducer,
});

// Persist the reducer
const persistedReducer = persistReducer(persistConfig, rootReducer);

// configure store
export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }),
});

export const persistor = persistStore(store);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
