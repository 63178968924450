import "../../../assets/scss/google-cloud-docker-file-list-style.scss";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Button } from "antd";
import DockerItem from "../DockerItem";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  clearGoogleDriveState,
  getGoogleDriveState,
} from "../../utility/common/session-store";
import {
  clearSelectedDocker,
  updateCurrDockerFolderName,
  updateDockerList,
  updateFolderPath,
} from "../../features/cloud/cloudSlice";
import { RollbackOutlined } from "@ant-design/icons";
import { getDockerFileList } from "../../server/GoogleDrive";
import DockerCreateFolder from "../DockerCreateFolder";

const baseURL = process.env.REACT_APP_API_BASE_URL;
const cloudRoute = process.env.REACT_APP_CLOUD_REDIRECT_ROUTE;

const DockerFileList = (props: any) => {
  const { t } = useTranslation();
  const { dockerList, goBacktoGoogleList } = props;
  const [treatedState, setTreatedState] = useState<any>("");
  const [treatedList, setTreatedList] = useState<any>([]);
  const cloudState = useAppSelector((state: any) => state.cloud);
  const userState = useAppSelector((state: any) => state.user);
  const folderPath = useAppSelector((state: any) => state.cloud.folderPath);
  const selectedDocker = useAppSelector(
    (state: any) => state.cloud.selectedDocker
  );

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const parseFileString = (fileString: string) => {
    if (dockerList == "") setTreatedList([]);
    if (!fileString) return [];
    // Split the input string into entries based on the file delimiter (-rw or drwx)
    const fileEntries = fileString
      .split(/(?=^-rw|^-d)/m)
      .flatMap((entry) => entry.split("\n").map((e) => e.trim())); // Remove '\n' and trim entries

    const treatedFE = fileEntries.filter((item) => item);
    // Process each entry into structured data
    const matches = treatedFE.map((entry) => {
      const parts = entry.trim().split(/\s+/); // Split by whitespace
      if (!parts) return null; // Ignore malformed entries

      return {
        permissions: parts[0], // Permissions (-rw-rw-r-- or drwxrwxr-x)
        size: parts[3], // File size as a number
        date: parts.slice(4, 7).join(" "), // Date in format '十二 25 15:39'
        name:
          parts.length > 8 ? parts.slice(7, parts.length).join(" ") : parts[7], // File or folder name, remove surrounding quotes
      };
    });

    // Filter out null (malformed) entries
    const res = matches.filter((match) => match !== null);

    setTreatedList(res);
  };

  const returnToDrive = () => {
    dispatch(updateFolderPath(""));
    dispatch(updateCurrDockerFolderName(""));
    dispatch(clearSelectedDocker());
    if (goBacktoGoogleList !== undefined) {
      goBacktoGoogleList();
    } else {
      navigate("/cloud");
    }
  };

  const returnToPrevPage = () => {
    getDockerFileList(treatedState!, {
      command: "2",
      new_file_name: "",
      folder: "",
      search: "",
      file: "",
      instance_id: selectedDocker.instance_id,
      machine_id: selectedDocker.machine_id,
      user_id: userState.user_id,
    })
      .then((res) => {
        dispatch(updateDockerList(res.data.file_result));
        const trimmedInput = folderPath.endsWith("/")
          ? folderPath.slice(0, -1)
          : folderPath; // Remove trailing slash if it exists
        const lastSlashIndex = trimmedInput.lastIndexOf("/"); // Find the last slash

        const beforeLastSlash = trimmedInput.substring(0, lastSlashIndex);
        const lastFolderIndex = beforeLastSlash.lastIndexOf("/");
        const lastFolderName = beforeLastSlash.substring(lastFolderIndex, -1);
        const foldername =
          lastFolderIndex === 0
            ? beforeLastSlash.split("/")[1]
            : lastFolderName.split("/")[1];
        dispatch(updateFolderPath(beforeLastSlash));
        foldername === undefined
          ? dispatch(updateCurrDockerFolderName(""))
          : dispatch(updateCurrDockerFolderName(foldername)); // for root if not in folder
      })
      .catch((err) => {
        console.log(err);
        clearGoogleDriveState();
        dispatch(updateFolderPath(""));
        navigate("/cloud");
      });
  };

  useEffect(() => {
    parseFileString(dockerList); // single source of truth
  }, [dockerList]);

  useEffect(() => {
    let newState = getGoogleDriveState();
    setTreatedState(newState);

    if (Object.keys(cloudState.selectedDocker).length == 0) {
      navigate("/instances");
    }
  }, []);

  return (
    <>
      <div className="docker-file-list-title">
        <h1>{t("File List")}</h1>
        <div className="title-button-group">
          {treatedState == undefined || treatedState === "" ? (
            <>
              <Button
                type="primary"
                href={`${baseURL}getGoogleDriveOAuth?urlName=${cloudRoute}&user_id=${userState.user_id}`}
              >
                <img src={`./images/GoogleDriveBtn/btn.png`} width={14} />
                {t("Connect to Google Drive")}
              </Button>
            </>
          ) : (
            <Button type="primary" onClick={() => returnToDrive()}>
              <img src={`./images/GoogleDriveBtn/btn.png`} width={14} />
              {t("Go Back to Google Drive")}
            </Button>
          )}
          {folderPath !== "" && (
            <Button type="default" onClick={() => returnToPrevPage()}>
              <RollbackOutlined />
              {t("Return to Previous Layer")}
            </Button>
          )}
        </div>
      </div>
      <div className="file-list">
        <div className="container">
          <div className="header">
            <span className="column">{t("Name")}</span>
            <span className="column">{t("Date")}</span>
            <span className="column">{t("Size")}</span>
          </div>
          {treatedList.map((file: any, index: number) => (
            <DockerItem
              key={index}
              state={treatedState}
              dockerList={dockerList}
              file={file}
            />
          ))}
          {treatedList.length === 0 && (
            <div style={{ textAlign: "center", padding: "1em" }}>
              {t("No Files Yet")}
            </div>
          )}
        </div>
      </div>
      <DockerCreateFolder />
    </>
  );
};

export default DockerFileList;
