import { RightOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";

const CloudBreadcrumb = (props: any) => {
  const { t } = useTranslation();
  const { backtoBaseLvlFn, folderStructure, goToFolderBreadcrumbFn } = props;
  return (
    <div className="breadcrumb">
      <span className="breadcrumb-item" onClick={() => backtoBaseLvlFn()}>
        <img src={`./images/GoogleDriveBtn/folder.png`} width={18} /> &nbsp;
        {t("My Drive")}
      </span>
      {folderStructure.map((item: any, index: number) => (
        <>
          <RightOutlined />
          <span
            key={index}
            className="breadcrumb-item"
            onClick={() => goToFolderBreadcrumbFn(item.route)}
          >
            <img src={`./images/GoogleDriveBtn/folder.png`} width={18} /> &nbsp;
            {item.name}
          </span>
        </>
      ))}
    </div>
  );
};

export default CloudBreadcrumb;
