import axios, { AxiosInstance } from "axios";
import {
  clearAllSessions,
  clearGoogleDriveState,
  getAccessTokenSession,
  getRefreshTokenSession,
  setAccessTokenSession,
  setRefreshTokenSession,
} from "../utility/common/session-store";
import { ErrorEnum } from "../utility/enum/error.enum";
import { getCookie } from "../utility/common/common";

let store: any;

// 把 redux store 抓出來，好拿資料
export const injectStore = (_store: any) => {
  store = _store;
};

export const updateHeaderInterceptor = (axiosInstance: AxiosInstance) => {
  axiosInstance.interceptors.request.use(
    async (config) => {
      if (
        !config.url?.includes("login") &&
        !config.url?.includes("loginId") &&
        !config.url?.includes("loginGoogleUser") &&
        !config.url?.includes("signupUser") &&
        !config.url?.includes("signupProvider") &&
        !config.url?.includes("template") &&
        !config.url?.includes("gpuHost") &&
        !config.url?.includes("getFilesListFromGoogleDrive")
      ) {
        const token = getAccessTokenSession();
        if (config.headers && token) {
          config.headers["Authorization"] = `Bearer ${token} `;
          return config;
        }
      } else {
        return config;
      }
    },
    (error) => {
      if (error === ErrorEnum.None) {
        window.location.href = "/login";
        window.location.reload();
      }
    }
  );
};

export const errorInterceptor = (axiosInstance: AxiosInstance) => {
  axiosInstance.interceptors.response.use(
    (response) => {
      return response;
    },
    async (error) => {
      const originalRequest = error.config;
      if (error === ErrorEnum.None) {
        console.log(error);
        window.location.href = "/login";
        window.location.reload();
      }

      if (error.response.status === 500) {
        if (error.response.data.msg.includes("Invalid state")) {
          clearGoogleDriveState();
        }
      }

      if (error.response.status === 400) {
        console.log("400 error");
      }

      if (error.response.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;
        // Unauthorized
        // retry token else redirect to Login

        // TODO: token 機制，之後這邊要把 at 變成 in mem w/ query
        try {
          const accessToken = getAccessTokenSession();
          const refreshToken = getRefreshTokenSession();
          const response = await axios.post(
            `${process.env.REACT_APP_API_BASE_URL}refreshToken`,
            {
              access_token: accessToken,
              refresh_token: refreshToken,
            },
            {
              headers: { Authorization: `Bearer ${refreshToken}` },
            }
          );
          const data = await response.data;
          setAccessTokenSession(data.token.access_token);
          setRefreshTokenSession(data.token.refresh_token);
          return axiosInstance(originalRequest);
        } catch (refreshError: any) {
          console.log(refreshError);
          clearAllSessions();
          window.location.href = "/login";
          window.location.reload();
        }
      }
    }
  );
};
