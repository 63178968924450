import "../../../assets/scss/google-cloud-download-modal-style.scss";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Button, message, Modal, Skeleton, Spin } from "antd";
import {
  BranchesOutlined,
  DesktopOutlined,
  LoadingOutlined,
  RobotOutlined,
} from "@ant-design/icons";

import { downloadDriveToDocker } from "../../server/GoogleDrive";
import { getGPUInstnace } from "../../server/GPU";
import { useAppSelector } from "../../store/hooks";
import { InstanceCardInterface } from "../../utility/interface/instance-card";
import {
  clearGoogleDriveState,
  getGoogleDriveState,
} from "../../utility/common/session-store";

const CloudDownloadMdoal = (props: any) => {
  const { t } = useTranslation();
  const { state, file_id, modalState, onCancelfn, user_id } = props;
  const userState = useAppSelector((state: any) => state.user);
  const [loading, setLoading] = useState<boolean>(false);
  const [downloading, setDownloading] = useState<boolean>(false);
  const [instancesList, setInstancesList] = useState<InstanceCardInterface[]>(
    []
  );

  const [messageApi, contextHolder] = message.useMessage();
  const navigate = useNavigate();

  const fetchInstanceList = async () => {
    const response = await getGPUInstnace(userState.user_id);
    const data = await response.data;
    setLoading(false);
    if (data.gpu_instance !== null) {
      setInstancesList(data.gpu_instance.reverse());
    } else {
      setInstancesList([]);
    }
  };

  const downloadDriveToDockerFn = (instance_id: string, machine_id: string) => {
    let treatedState = state?.split("|user_id")[0];
    downloadDriveToDocker(treatedState, {
      file_id: file_id,
      instance_id: instance_id,
      destination_path: `/home/gveda/user_data/${instance_id}/`,
      machine_id: machine_id,
      user_id: user_id,
    })
      .then((res) => {
        if (res.data.code === 0) {
          downloadFileSuccess();
        } else {
          downloadFileFailed();
        }
      })
      .catch((err) => {
        downloadFileFailed();
        if (getGoogleDriveState() == "") {
          stateError();
          navigate("/cloud");
        }
      });
  };

  const downloadFileSuccess = () => {
    messageApi.success(t("Download Success!"), 2.5).then(() => {
      setDownloading(false);
      onCancelfn();
    });
  };

  const downloadFileFailed = () => {
    messageApi.error(t("Download Failed!"), 2.5).then(() => {
      setDownloading(false);
      onCancelfn();
    });
  };
  const stateError = () => {
    messageApi.error({
      content: t("Please connect to Google Drive!"),
    });
  };

  useEffect(() => {
    if (userState.user_id) {
      setLoading(true);
      fetchInstanceList();
    }
  }, [useState]);

  return (
    <>
      {contextHolder}
      {downloading == false && (
        <Modal
          open={modalState}
          onCancel={() => onCancelfn()}
          width={"auto"}
          className="google-cloud-download-modal"
          footer={null}
          closable={!loading}
          centered
        >
          {instancesList.length === 0 && loading == false && (
            <div className="no-instances">
              <p>{t("No Machines Yet")}</p>
              <Button onClick={() => navigate("/")}>{t("Go to Rental")}</Button>
            </div>
          )}
          {loading === true ? (
            <div
              style={{
                display: "flex",
                alignItems: "middle",
                justifyContent: "center",
              }}
            >
              <Spin
                indicator={<LoadingOutlined spin style={{ fontSize: 72 }} />}
              />
            </div>
          ) : (
            <div className="file-cloud-download">
              {instancesList.map((item: any, index: any) => {
                return (
                  <div
                    key={index}
                    className="instance-item"
                    onClick={() => {
                      setLoading(true);
                      setDownloading(true);
                      messageApi.loading({
                        content: t("Downloading..."),
                        duration: 0,
                      });
                      downloadDriveToDockerFn(
                        item.instance_id,
                        item.machine_id
                      );
                    }}
                  >
                    <li>
                      <DesktopOutlined /> ID: {item.instance_id}
                    </li>
                    <li>
                      <RobotOutlined /> {t("Machine")}: {item.product_name}
                    </li>
                    <li>
                      <BranchesOutlined /> {t("GPU Count")}: {item.gpu_count}{" "}
                      GPU
                    </li>
                    <strong>{t("Download to This Instance")}</strong>
                  </div>
                );
              })}
            </div>
          )}
        </Modal>
      )}
    </>
  );
};

export default CloudDownloadMdoal;
