import "../../../assets/scss/rental-card-style.scss";
import { useEffect, useState } from "react";
import { Button, Empty, Input, Skeleton, Slider } from "antd";
import type { SliderSingleProps } from "antd";

import { RentalCard } from "../../components/rentalCard";

import { RentalCardInterface } from "../../utility/interface/rental-card";
import { between } from "../../utility/fn";
import { getGPUHostList, updateInstancePublicKey } from "../../server/GPU";
import { useTranslation } from "react-i18next";

const bandwidthMarks: SliderSingleProps["marks"] = {
  32: "32 GB",
  8192: "8192 GB",
};

const displayGPURamMarks: SliderSingleProps["marks"] = {
  12: "12 GB",
  1280: "1280 GB",
};

const storageMarks: SliderSingleProps["marks"] = {
  1: "<1 TB",
  72: "72 TB",
};

const fakeData: RentalCardInterface[] = [
  {
    machine_id: 0,
    product_name: "NVIDIA RTX 4090",
    price: 0.32,
    gpu_ram: "96",
    gpu_count: 0,
    total_gpu_count: 16,
    effective_ram: "2451",
    teraflops: 340.13,
    cpu_name: "6× Xeon E5-2680 v4",
    internet_upload_speed: 1967,
    internet_download_speed: 6222,
    disk_bandwidth: 128,
    deep_learning_performance_score: 8096.4,
    disk_max_available_storage: 1024,
  },
  {
    machine_id: 1,
    product_name: "NVIDIA RTX 4080",
    price: 0.11,
    gpu_ram: "128",
    gpu_count: 0,
    total_gpu_count: 8,
    effective_ram: "1651",
    teraflops: 340.13,
    cpu_name: "6× Xeon E5-2680 v4",
    internet_upload_speed: 4100,
    internet_download_speed: 8385,
    disk_bandwidth: 32,
    deep_learning_performance_score: 8096.4,
    disk_max_available_storage: 512,
  },
  {
    machine_id: 2,
    product_name: "NVIDIA RTX 3090",
    price: 0.12,
    gpu_ram: "192",
    gpu_count: 0,
    total_gpu_count: 8,
    effective_ram: "2451",
    teraflops: 340.13,
    cpu_name: "6× Xeon E5-2680 v3",
    internet_upload_speed: 2708,
    internet_download_speed: 8077,
    disk_bandwidth: 521,
    deep_learning_performance_score: 8096.4,
    disk_max_available_storage: 128,
  },
  {
    machine_id: 3,
    product_name: "NVIDIA RTX 3080 TI",
    price: 0.05,
    gpu_ram: "48",
    gpu_count: 0,
    total_gpu_count: 4,
    effective_ram: "1251",
    teraflops: 340.13,
    cpu_name: "3× Xeon Gold 5318Y",
    internet_upload_speed: 557,
    internet_download_speed: 788,
    disk_bandwidth: 64,
    deep_learning_performance_score: 8096.4,
    disk_max_available_storage: 256,
  },
  {
    machine_id: 4,
    product_name: "NVIDIA RTX 3080",
    price: 0.07,
    gpu_ram: "40",
    gpu_count: 0,
    total_gpu_count: 4,
    effective_ram: "1251",
    teraflops: 340.13,
    cpu_name: "3× Xeon Gold 5318Y",
    internet_upload_speed: 2215,
    internet_download_speed: 7991,
    disk_bandwidth: 32,
    deep_learning_performance_score: 8096.4,
    disk_max_available_storage: 256,
  },
  {
    machine_id: 5,
    product_name: "NVIDIA RTX A4000",
    price: 0.06,
    gpu_ram: "128",
    gpu_count: 0,
    total_gpu_count: 8,
    effective_ram: "1651",
    teraflops: 340.13,
    cpu_name: "16× AMD EPYC 7542 32-Core Processor",
    internet_upload_speed: 496,
    internet_download_speed: 1984,
    disk_bandwidth: 512,
    deep_learning_performance_score: 8096.4,
    disk_max_available_storage: 4096,
  },
  {
    machine_id: 6,
    product_name: "NVIDIA RTX A5000",
    price: 0.14,
    gpu_ram: "192",
    gpu_count: 0,
    total_gpu_count: 8,
    effective_ram: "2451",
    teraflops: 340.13,
    cpu_name: "12× Xeon Platinum 8260",
    internet_upload_speed: 607,
    internet_download_speed: 2428,
    disk_bandwidth: 64,
    deep_learning_performance_score: 8096.4,
    disk_max_available_storage: 1024,
  },
  {
    machine_id: 7,
    product_name: "NVIDIA Tesla V100-16GB",
    price: 0.2,
    gpu_ram: "128",
    gpu_count: 0,
    total_gpu_count: 8,
    effective_ram: "1651",
    teraflops: 340.13,
    cpu_name: "10× Xeon Platinum 81",
    internet_upload_speed: 865,
    internet_download_speed: 3460,
    disk_bandwidth: 64,
    deep_learning_performance_score: 8096.4,
    disk_max_available_storage: 512,
  },
  {
    machine_id: 8,
    product_name: "NVIDIA RTX 6000ADA",
    price: 0.9,
    gpu_ram: "384",
    gpu_count: 0,
    total_gpu_count: 8,
    effective_ram: "4851",
    teraflops: 340.13,
    cpu_name: "10× Xeon Platinum 81",
    internet_upload_speed: 1013,
    internet_download_speed: 4052,
    disk_bandwidth: 32,
    deep_learning_performance_score: 8096.4,
    disk_max_available_storage: 128,
  },
  {
    machine_id: 9,
    product_name: "NVIDIA H100 PCIE",
    price: 2.49,
    gpu_ram: "320",
    gpu_count: 0,
    total_gpu_count: 4,
    effective_ram: "2580",
    teraflops: 340.13,
    cpu_name: "10× Xeon Platinum 81",
    internet_upload_speed: 901,
    internet_download_speed: 3604,
    disk_bandwidth: 64,
    deep_learning_performance_score: 8096.4,
    disk_max_available_storage: 4096,
  },
  {
    machine_id: 10,
    product_name: "NVIDIA H100 NVL",
    price: 2.8,
    gpu_ram: "752",
    gpu_count: 0,
    total_gpu_count: 8,
    effective_ram: "1296",
    teraflops: 340.13,
    cpu_name: "10× Xeon Platinum 81",
    internet_upload_speed: 817,
    internet_download_speed: 3268,
    disk_bandwidth: 256,
    deep_learning_performance_score: 8096.4,
    disk_max_available_storage: 256,
  },
];

export const Dashboard = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(false);
  const [gpuList, setGPUList] = useState<RentalCardInterface[]>([]);
  const [searched, setSearched] = useState<string>("");
  const [filteredGPUList, setfilteredGPUList] = useState<RentalCardInterface[]>(
    []
  );
  const [effectiveRamRange, setEffectiveRamRange] = useState<number[]>([
    32, 8192,
  ]); // 內存
  const [gpuRamRange, setGPURamRange] = useState<number[]>([12, 1280]); // 顯存
  const [diskMaxAvailableStorageRange, setDiskMaxAvailableStorageRange] =
    useState<number[]>([1, 72]); // 硬盤 in TB

  useEffect(() => {
    let list = gpuList;
    if (searched !== "") {
      list = list.filter((gpu) => {
        return gpu.product_name.toLowerCase().includes(searched);
      });
    }
    setfilteredGPUList(list);
    let filtered = list.filter((gpu) => {
      return (
        between(
          Number(gpu.effective_ram),
          effectiveRamRange[0],
          effectiveRamRange[1]
        ) == true
      );
    });

    filtered = filtered.filter((gpu) => {
      return (
        between(
          Number(Math.ceil(gpu.disk_max_available_storage / 1000)), // Transform the available disk from GB to TB
          diskMaxAvailableStorageRange[0],
          diskMaxAvailableStorageRange[1]
        ) == true
      );
    });

    filtered = filtered.filter((gpu) => {
      return (
        between(Number(gpu.gpu_ram), gpuRamRange[0], gpuRamRange[1]) == true
      );
    });

    setfilteredGPUList(filtered);
  }, [searched, gpuRamRange, effectiveRamRange, diskMaxAvailableStorageRange]);

  const onChangeInput = (e: any) => {
    const list = gpuList;
    const value = e.target.value;

    const filtered = list.filter((gpu) => {
      return gpu.product_name.toLowerCase().includes(value);
    });
    setfilteredGPUList(filtered);
    setSearched(value);
  };

  const resetList = () => {
    const list = gpuList;
    setfilteredGPUList(list);
    setSearched("");
    setGPURamRange([12, 1280]);
    setEffectiveRamRange([32, 8192]);
    setDiskMaxAvailableStorageRange([1, 72]);
  };

  useEffect(() => {
    setLoading(true);
    fetchGPUList();
  }, []);

  const fetchGPUList = async () => {
    const response = await getGPUHostList(1, 20);
    const data = await response.data;
    const listWithFakeData = [...data.gpu_host, ...fakeData]; // to be removed later
    setLoading(false);
    // setGPUList(data.gpu_host); // single source of truth
    setGPUList(listWithFakeData); // single source of truth
    setGPURamRange([12, 1280]);
    setEffectiveRamRange([32, 8192]);
    setDiskMaxAvailableStorageRange([1, 72]);
    setfilteredGPUList(listWithFakeData);
  };

  return (
    <>
      <div>
        <h3>{t("Machine Selection")}</h3>
        <div className="rental-sorting">
          <div className="search-bar">
            <Input
              placeholder={t("Search Machines...")}
              size="large"
              defaultValue={searched}
              value={searched}
              onChange={onChangeInput}
            />
          </div>
          <div className="slider-wrapper">
            <div className="slider">
              <span>{t("GPU RAM")}:</span>
              <Slider
                range={{ draggableTrack: true }}
                defaultValue={gpuRamRange}
                min={12}
                max={1280}
                marks={displayGPURamMarks}
                onChange={(value: any) => setGPURamRange(value)}
              />
            </div>
            <div className="slider">
              <span>{t("Effective RAM")}:</span>
              <Slider
                range={{ draggableTrack: true }}
                defaultValue={effectiveRamRange}
                value={effectiveRamRange}
                min={32}
                max={8192}
                marks={bandwidthMarks}
                onChange={(value: any) => setEffectiveRamRange(value)}
              />
            </div>
            <div className="slider">
              <span>{t("Disk Storage")}:</span>
              <Slider
                range={{ draggableTrack: true }}
                defaultValue={diskMaxAvailableStorageRange}
                value={diskMaxAvailableStorageRange}
                min={1}
                max={72}
                marks={storageMarks}
                onChange={(value: any) =>
                  setDiskMaxAvailableStorageRange(value)
                }
              />
            </div>
          </div>
        </div>
      </div>
      <h3>{t("All Machines")}</h3>
      <div className="rental-card-container">
        {loading && <Skeleton active />}

        {/* quick fix for setting homee ai machines first TODO: ask b2e to change order later*/}
        {filteredGPUList.map((item: any) => {
          if (
            item.machine_id === 123142 ||
            item.machine_id === 123145 ||
            item.machine_id === 111112
          ) {
            return (
              <RentalCard
                key={item.machine_id}
                machine_id={item.machine_id}
                product_name={item.product_name}
                price={item.price}
                gpu_count={item.gpu_count}
                internet_upload_speed={item.internet_upload_speed}
                internet_download_speed={item.internet_download_speed}
                gpu_ram={item.gpu_ram}
                cpu_name={item.cpu_name}
                effective_ram={item.effective_ram}
                disk_max_available_storage={item.disk_max_available_storage}
                deep_learning_performance_score={
                  item.deep_learning_performance_score
                }
                teraflops={item.teraflops}
                total_gpu_count={item.total_gpu_count}
              />
            );
          }
        })}
        {filteredGPUList
          .filter((item) => item.machine_id !== 111112)
          .filter((item) => item.machine_id !== 123145)
          .filter((item) => item.machine_id !== 123142)
          .map((item: any) => {
            return (
              <RentalCard
                key={item.machine_id}
                machine_id={item.machine_id}
                product_name={item.product_name}
                price={item.price}
                gpu_count={item.gpu_count}
                internet_upload_speed={item.internet_upload_speed}
                internet_download_speed={item.internet_download_speed}
                gpu_ram={item.gpu_ram}
                cpu_name={item.cpu_name}
                effective_ram={item.effective_ram}
                disk_max_available_storage={item.disk_max_available_storage}
                deep_learning_performance_score={
                  item.deep_learning_performance_score
                }
                teraflops={item.teraflops}
                total_gpu_count={item.total_gpu_count}
              />
            );
          })}
      </div>
      {filteredGPUList.length === 0 && (
        <>
          {loading == false && (
            <div className="instance-card-none">
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description={<>{t("No matching machine.")}</>}
              />
              <Button
                type="primary"
                onClick={() => {
                  resetList();
                }}
              >
                {t("Reset Filter")}
              </Button>
            </div>
          )}
        </>
      )}
    </>
  );
};
