import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import {
  CloudFolderStuctureInterface,
  CurrListInterface,
} from "../../utility/interface/cloud";

const initialState: any = {
  dockerList: "",
  selectedDocker: {},
  currList: [],
  folderPath: "",
  currDockerFolderName: "",
  folderStructure: [],
  nextPageToken: "",
};

export const cloudSlice = createSlice({
  name: "cloud",
  initialState,
  reducers: {
    updateCurrList: (state, action: PayloadAction<CurrListInterface>) => {
      state.currList = action.payload;
    },
    updateDockerList: (state, action: PayloadAction<string>) => {
      state.dockerList = action.payload;
    },
    updatedSelectedDocker: (state, action: PayloadAction<{}>) => {
      state.selectedDocker = action.payload;
    },
    clearSelectedDocker: (state) => {
      state.selectedDocker = {};
    },
    addToCurrList: (state, action: PayloadAction<CurrListInterface[]>) => {
      state.currList = [...state.currList, ...action.payload];
    },
    goDeeperIntoFolder: (
      state,
      action: PayloadAction<CloudFolderStuctureInterface[]>
    ) => {
      state.folderStructure = [...state.folderStructure, ...action.payload];
    },
    updateFolderStructure: (
      state,
      action: PayloadAction<CloudFolderStuctureInterface[]>
    ) => {
      state.folderStructure = action.payload;
    },
    updateFolderPath: (state, action: PayloadAction<string>) => {
      state.folderPath = action.payload;
    },
    updateCurrDockerFolderName: (state, action: PayloadAction<string>) => {
      state.currDockerFolderName = action.payload;
    },
    backtoBaseStructure: (state) => {
      state.folderStructure = [];
    },
    updateNextPageToken: (state, action: PayloadAction<string>) => {
      state.nextPageToken = action.payload;
    },
    removeCloud: (state) => {
      return initialState;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  addToCurrList,
  backtoBaseStructure,
  updateCurrList,
  updateDockerList,
  updateFolderPath,
  updateCurrDockerFolderName,
  updatedSelectedDocker,
  clearSelectedDocker,
  goDeeperIntoFolder,
  updateFolderStructure,
  updateNextPageToken,
  removeCloud,
} = cloudSlice.actions;

export default cloudSlice.reducer;
