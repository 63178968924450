import "../../../assets/scss/billings-style.scss";

import {
  Button,
  ConfigProvider,
  Divider,
  Skeleton,
  Table,
  TableProps,
} from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { getRentalHistory } from "../../server/GPU";
import { useAppSelector } from "../../store/hooks";
import moment from "moment";
import { useEffect, useState } from "react";

interface RentalHistoryDataType {
  transaction_id?: number;
  user_id?: number;
  provider_id?: number;
  product_name?: string;
  status?: string;
  machine_id?: number;
  instance_id?: number;
  template_name?: string;
  gpu_count?: number;
  payment_id?: number;
  total_amount?: number;
  created_at?: number;
  end_at?: number;
}

export const Billings = () => {
  const { t } = useTranslation();
  const userState = useAppSelector((state: any) => state.user);
  const [rentalHistoryData, setRentalHistoryData] = useState<
    RentalHistoryDataType[]
  >([]);
  const [loading, setLoading] = useState<boolean>(false);

  const rentalHistoryColumns: TableProps<RentalHistoryDataType>["columns"] = [
    {
      title: t("Created Date"),
      dataIndex: "created_at",
      key: "created_at",
      render: (value) => <>{moment.unix(value).format("YYYY-MM-DD")}</>,
    },
    {
      title: t("Provider Id"),
      dataIndex: "provider_id",
      key: "provider_id",
    },
    {
      title: t("Product Name"),
      dataIndex: "product_name",
      key: "product_name",
    },
    {
      title: t("Machine Id"),
      dataIndex: "machine_id",
      key: "machine_id",
    },
    {
      title: t("Instance Id"),
      dataIndex: "instance_id",
      key: "instance_id",
    },
    {
      title: t("Template Name"),
      dataIndex: "template_name",
      key: "template_name",
      render: (value) => <>{treatTemplateName(value)}</>,
    },
    {
      title: t("GPU Count"),
      dataIndex: "gpu_count",
      key: "gpu_count",
    },
    {
      title: t("Transaction Id"),
      dataIndex: "transaction_id",
      key: "transaction_id",
    },

    {
      title: t("Price"),
      dataIndex: "total_amount",
      key: "total_amount",
      render: (value) => <strong>USD ${Number(value).toFixed(2)}</strong>,
    },
  ];

  const treatTemplateName = (templateName: string) => {
    const baseName = templateName.replace(/-\d+$/, "");
    return baseName;
  };

  const customizeRenderEmpty = () => (
    <div style={{ textAlign: "center" }}>
      <p>{t("No Data Yet")}</p>
    </div>
  );

  const getRentalHistoryData = () => {
    setLoading(true);
    getRentalHistory(userState.user_id)
      .then((res: any) => {
        setRentalHistoryData(res.data.rental_history);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getRentalHistoryData();
  }, []);

  return (
    <>
      <div className="wrapper">
        <h4>{t("Billings")}</h4>
        {loading ? (
          <Skeleton active paragraph />
        ) : rentalHistoryData.length === 0 ? (
          <>{t("No Data Yet")}</>
        ) : (
          <ConfigProvider renderEmpty={customizeRenderEmpty}>
            <Table
              className="table-striped-rows"
              columns={rentalHistoryColumns}
              dataSource={rentalHistoryData}
              showHeader={rentalHistoryData.length !== 0}
              tableLayout="fixed"
              pagination={false}
              scroll={{ x: "max-content" }}
              loading={loading}
            />
          </ConfigProvider>
        )}
      </div>
    </>
  );
};
