import "../../../assets/scss/instance-card-style.scss";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Route, Routes, useNavigate, useSearchParams } from "react-router-dom";

import { Button, message, Space, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

import {
  getDockerFileList,
  getFilesListFromGoogleDrive,
} from "../../server/GoogleDrive";

import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { updateMenuKey } from "../../features/settings/settingsSlice";
import FileUploadModal from "../../components/FileUploadMoal";

import {
  addToCurrList,
  backtoBaseStructure,
  clearSelectedDocker,
  updateCurrList,
  updateDockerList,
  updateFolderStructure,
  updateNextPageToken,
} from "../../features/cloud/cloudSlice";
import GoogleCloudFileList from "../../components/GoogleCloudFileList";
import DockerFileList from "../../components/DockerFileList";
import InstanceSelectModal from "../../components/InstanceSelectModal";
import {
  clearGoogleDriveState,
  getGoogleDriveState,
  setGoogleDriveState,
} from "../../utility/common/session-store";

const baseURL = process.env.REACT_APP_API_BASE_URL;
const cloudRoute = process.env.REACT_APP_CLOUD_REDIRECT_ROUTE;

export const Cloud = () => {
  const { t } = useTranslation();
  const userState = useAppSelector((state: any) => state.user);
  const [baseFileList, setBaseFileList] = useState<any>([]);
  const [basePageToken, setBasePageToken] = useState<any>("");
  const [fileListLoading, setFileListLoading] = useState<boolean>(false);
  const [initialLoading, setInitialLoading] = useState<boolean>(false);
  const [connect, setConnect] = useState<boolean>(false);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [fileUploadModal, setFileUploadModal] = useState<boolean>(false);
  const [googleList, setGoogleList] = useState<boolean>(true);
  const [instanceModalState, setInstanceModalState] = useState<boolean>(false);
  const [messageApi, contextHolder] = message.useMessage();

  let [searchParams, setSearchParams] = useSearchParams();
  let state = searchParams.get("state");
  let prevState = getGoogleDriveState(); // check to see if connected in other places before

  const navigate = useNavigate();
  const fileList = useAppSelector((state: any) => state.cloud.currList);
  const dockerList = useAppSelector((state: any) => state.cloud.dockerList);
  const folderStructure = useAppSelector(
    (state: any) => state.cloud.folderStructure
  );
  const nextPageToken = useAppSelector(
    (state: any) => state.cloud.nextPageToken
  );
  const dispatch = useAppDispatch();

  const getFiles = async () => {
    let treatedState = getGoogleDriveState();
    setInitialLoading(true);

    getFilesListFromGoogleDrive(treatedState!, {
      page_size: 20,
      page_token: "",
      search_query: searchQuery ? `name%20contains%20%22${searchQuery}%22` : "",
    })
      .then((res) => {
        baseFileList.length === 0 && setBaseFileList(res.data.files);
        basePageToken === "" && setBasePageToken(res.data.next_page_token);
        dispatch(updateCurrList(res.data.files));
        dispatch(updateNextPageToken(res.data.next_page_token));
        setInitialLoading(false);
      })
      .catch((err) => {
        console.log(err);
        clearGoogleDriveState();
        setInitialLoading(false);
        setConnect(false);
        navigate("/cloud");
      });
  };

  const getNextPageFiles = async () => {
    let treatedState = getGoogleDriveState();
    setFileListLoading(true);

    getFilesListFromGoogleDrive(treatedState!, {
      page_size: 20,
      page_token: nextPageToken,
      search_query: searchQuery ? `name%20contains%20%22${searchQuery}%22` : "",
    })
      .then((res) => {
        dispatch(addToCurrList(res.data.files));
        dispatch(updateNextPageToken(res.data.next_page_token));
        setFileListLoading(false);
      })
      .catch((err) => {
        dispatch(updateNextPageToken(""));
        setFileListLoading(false);
        clearGoogleDriveState();
        setConnect(false);
        navigate("/cloud");
      });
  };

  const backtoBaseLvl = () => {
    dispatch(updateNextPageToken(basePageToken));
    dispatch(updateCurrList(baseFileList));
    dispatch(backtoBaseStructure());
  };

  const goToFolderBreadcrumb = (route: string) => {
    let treatedState = getGoogleDriveState();
    let newStrucutre = [];
    let index = folderStructure.findIndex((item: any) => item.route === route);
    if (index !== -1) {
      // Slice the array to include only up to the matching object
      newStrucutre = folderStructure.slice(0, index + 1);
    }

    dispatch(updateFolderStructure(newStrucutre));
    getFilesListFromGoogleDrive(treatedState!, {
      page_size: 20,
      page_token: "",
      search_query: "'" + route + "' in parents",
    })
      .then((res: any) => {
        dispatch(updateCurrList(res.data.files));
        dispatch(updateNextPageToken(res.data.next_page_token));
      })
      .catch((err: any) => {
        setConnect(false);
        if (getGoogleDriveState() == "") {
          stateError();
        }
        navigate("/cloud");
      });
  };

  const stateError = () => {
    messageApi.error({
      content: t("Please connect to Google Drive!"),
    });
  };

  useEffect(() => {
    setConnect(false);
    if ((state !== null || undefined) && fileList.length === 0) {
      if (window.location.pathname === "/cloud") {
        dispatch(updateMenuKey("4"));
      }
      let treatedState = state?.split("|user_id")[0];
      setGoogleDriveState(treatedState!);
      getFiles();
      setConnect(true);
    } else if (prevState !== "") {
      getFiles();
      setConnect(true);
    }

    if (fileList.length !== 0 && (state == null || undefined)) {
      window.location.reload();
    }
  }, [state]);

  return (
    <>
      {contextHolder}
      {connect == false && !getGoogleDriveState() && (
        <Button
          type="primary"
          href={`${baseURL}getGoogleDriveOAuth?urlName=${cloudRoute}&user_id=${userState.user_id}`}
        >
          <img src={`./images/GoogleDriveBtn/btn.png`} width={14} />
          {t("Connect to Google Drive")}
        </Button>
      )}
      {initialLoading === true && fileList.length === 0 && (
        <div className="loading-wrapper">
          <Spin
            indicator={
              <LoadingOutlined
                spin
                style={{ fontSize: 72, marginBottom: ".5em" }}
              />
            }
          />
          <Space />
          <div>{t("Fetching files...")}</div>
        </div>
      )}
      {connect && fileList.length !== 0 && (
        <>
          {googleList ? (
            <GoogleCloudFileList
              backtoBaseLvlFn={backtoBaseLvl}
              changeListFn={() => setInstanceModalState(true)}
              fileList={fileList}
              fileListLoading={fileListLoading}
              folderStructure={folderStructure}
              getNextPageFilesFn={() => getNextPageFiles()}
              goToFolderBreadcrumbFn={goToFolderBreadcrumb}
              uploadFileFn={() => setFileUploadModal(true)}
              nextPageToken={nextPageToken}
            />
          ) : (
            <DockerFileList
              dockerList={dockerList}
              goBacktoGoogleList={() => setGoogleList(true)}
            />
          )}
        </>
      )}
      <FileUploadModal
        modalState={fileUploadModal}
        onCancelfn={() => setFileUploadModal(false)}
      />
      <InstanceSelectModal
        state={state}
        modalState={instanceModalState}
        switchtoDockerListFn={() => setGoogleList(false)}
        onCancelfn={() => setInstanceModalState(false)}
      />
    </>
  );
};
