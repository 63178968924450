import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";

import type { MenuProps } from "antd";
import { Menu } from "antd";

import {
  ContainerOutlined,
  MenuOutlined,
  ToolOutlined,
  RocketOutlined,
} from "@ant-design/icons";

import { googleLogout } from "@react-oauth/google";

import { useAppDispatch } from "../../store/hooks";
import { removeUserInfo } from "../../features/user/userSlice";
import { clearAllSessions } from "../../utility/common/session-store";
import { updateMenuKey } from "../../features/settings/settingsSlice";

type MenuItem = Required<MenuProps>["items"][number];

export const ProviderMainMenu = () => {
  const { t } = useTranslation();
  const [menuType, setMenuType] = useState<boolean>(true);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const toggleMenu = () => {
    setMenuType(!menuType);
  };

  const navigateLink = (menuKey: number, param: string) => {
    dispatch(updateMenuKey(menuKey));
    toggleMenu();
    navigate(param);
  };

  const providerMainMenuItems: MenuItem[] = [
    {
      key: "1",
      icon: <ToolOutlined />,
      label: (
        <div onClick={() => navigateLink(1, "/installation")}>
          {t("Installation")}
        </div>
      ),
    },
    {
      key: "2",
      icon: <ContainerOutlined />,
      label: (
        <div onClick={() => navigateLink(2, "/profits")}>{t("Profits")}</div>
      ),
    },
    {
      key: "3",
      icon: <RocketOutlined />,
      label: (
        <div onClick={() => navigateLink(3, "/machineStatus")}>
          {t("Machine Status")}
        </div>
      ),
    },
  ];

  const bottomMenuItems: MenuItem[] = [
    {
      key: "0",
      label: <div onClick={() => logOut()}>{t("Log Out")}</div>,
    },
  ];

  const logOut = () => {
    googleLogout();
    dispatch(removeUserInfo());
    clearAllSessions();
    navigate("/login");
  };

  return (
    <div className="main-menu">
      <aside>
        <div className="logo-wrapper">
          <Link to="/">
            <div className="logo" />
          </Link>
          <span className="mobile-menu" onClick={() => toggleMenu()}>
            <MenuOutlined />
          </span>
        </div>
        <div
          className={
            menuType ? "main-menu-wrapper" : "main-menu-wrapper show-mobile"
          }
        >
          <Menu
            theme="dark"
            mode="inline"
            defaultSelectedKeys={["1"]}
            items={providerMainMenuItems}
          />
          <Menu theme="dark" items={bottomMenuItems} />
        </div>
      </aside>
    </div>
  );
};
