import "../../assets/scss/login-style.scss";
import React from "react";
import { useTranslation } from "react-i18next";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { Col, message, Row } from "antd";
import * as Yup from "yup";
import { LangComp } from "../components/LangComp";

import { useNavigate } from "react-router-dom";
import { signupProvider } from "../server/Provider";

const SignUpProvider: React.FC = () => {
  const { t } = useTranslation();
  const [messageApi, contextHolder] = message.useMessage();
  const navigate = useNavigate();

  const SignupSchema = Yup.object().shape({
    family_name: Yup.string().required(t("Last Name Field is required")),
    given_name: Yup.string().required(t("First Name Field is required")),
    hd: Yup.string().required(t("Company Name Field is required")),
    email: Yup.string()
      .email(t("Incorrect Email format"))
      .required(t("Please provide your Email")),
    password: Yup.string()
      .min(6, t("Password must be longer than 6 characters"))
      .required(t("Password field is required")),
    confirmPassword: Yup.string()
      .required(t("Confirm Password field is required"))
      .oneOf([Yup.ref("password")], t("Password field is required")),
  });

  const signUpSuccess = () => {
    messageApi.success({
      content: t("Sign Up Success!"),
    });
  };

  const signUpError = () => {
    messageApi.error({
      content: t("Sign Up Failed!"),
    });
  };

  return (
    <>
      {contextHolder}
      <div className="login-page">
        <main className="login-container">
          <div className="login-card">
            <div className="login-card-left">
              <div className="logo" />
            </div>
            <div className="login-card-right provider-signup">
              <div className="right-side-middle">
                <h1>{t("Welcome to NeuroWatt!")}</h1>
                <h3>{t("Sign up and start using our services")}</h3>

                <div className="form">
                  <Formik
                    validateOnMount={true}
                    enableReinitialize={true}
                    validationSchema={SignupSchema}
                    initialValues={{}}
                    onSubmit={(values: any) => {
                      signupProvider({
                        ...values,
                        name: values.family_name + " " + values.given_name,
                        picture: "",
                      })
                        .then((res) => {
                          if (res.data.msg == "success") {
                            signUpSuccess();
                            setTimeout(() => {
                              navigate("/login");
                            }, 1000);
                          }
                        })
                        .catch((res) => {
                          console.log(res);
                          signUpError();
                        });
                    }}
                  >
                    {({ errors, touched }) => (
                      <Form>
                        <Row gutter={[0, 24]}>
                          <Col span={12}>
                            <div className="label-wrapper">
                              <label htmlFor="family_name">
                                {t("Last Name")}
                              </label>
                              <Field name="family_name" type="text" />
                              <div className="form-error-msg">
                                <ErrorMessage name="family_name" />
                              </div>
                            </div>
                          </Col>
                          <Col span={12}>
                            <div className="label-wrapper">
                              <label htmlFor="given_name">
                                {t("First Name")}
                              </label>
                              <Field name="given_name" type="text" />
                              <div className="form-error-msg">
                                <ErrorMessage name="given_name" />
                              </div>
                            </div>
                          </Col>
                        </Row>
                        <Row gutter={[0, 24]}>
                          <Col span={12}>
                            <div className="label-wrapper">
                              <label htmlFor="hd">{t("Company Name")}</label>
                              <Field name="hd" type="text" />
                              <div className="form-error-msg">
                                <ErrorMessage name="hd" />
                              </div>
                            </div>
                          </Col>
                          <Col span={12}>
                            <div className="label-wrapper">
                              <label htmlFor="email">{t("E-Mail")}</label>
                              <Field name="email" type="email" />
                              <div className="form-error-msg">
                                <ErrorMessage name="email" />
                              </div>
                            </div>
                          </Col>
                        </Row>
                        <Row gutter={[0, 24]}>
                          <Col span={12}>
                            <div className="label-wrapper">
                              <label htmlFor="password">{t("Password")}</label>
                              <Field name="password" type="password" />
                              <div className="form-error-msg">
                                <ErrorMessage name="password" />
                              </div>
                            </div>
                          </Col>
                          <Col span={12}>
                            <div className="label-wrapper">
                              <label htmlFor="confirmPassword">
                                {t("Confirm Password")}
                              </label>
                              <Field name="confirmPassword" type="password" />
                              <div className="form-error-msg">
                                <ErrorMessage name="confirmPassword" />
                              </div>
                            </div>
                          </Col>
                        </Row>

                        <Row gutter={[0, 24]}>
                          <Col span={12}>
                            <button className="sign-up-btn" type="submit">
                              {t("Sign Up")}
                            </button>
                          </Col>
                        </Row>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
              <div className="right-side-bottom">
                <footer>
                  <span>
                    {t("Language Selected")}: <LangComp type="text" />
                  </span>
                  <div className="logo-black" />
                  <span>Copyright © 2024 NeuroWatt. All rights reserved.</span>
                </footer>
              </div>
            </div>
          </div>
        </main>
      </div>
    </>
  );
};

export default SignUpProvider;
