import "../../../assets/scss/google-drive-card-style.scss";
import React, { useState } from "react";
import { Button, message, Modal } from "antd";

import { uploadToGoogleDrive } from "../../server/GoogleDrive";
import { useTranslation } from "react-i18next";
import { getGoogleDriveState } from "../../utility/common/session-store";

const FileUploadModal = (props: any) => {
  const { t } = useTranslation();
  const { modalState, onCancelfn } = props;
  const [file, setFile] = useState<any>();
  const [loading, setLoading] = useState<boolean>(false);
  const [fileName, setFileName] = useState<string>("");

  const [messageApi, contextHolder] = message.useMessage();

  const uploadToGoogle = (file_name: string) => {
    let treatedState = getGoogleDriveState();
    const formData = new FormData();
    formData.append("file", file);
    formData.append("file_name", file_name);

    uploadToGoogleDrive(treatedState, formData)
      .then((res) => {
        setLoading(false);
        if (res.data.code === 0) {
          uploadFileSuccess();
        } else {
          uploadFileFailed();
        }

        setTimeout(() => {
          onCancelfn();
          window.location.reload();
        }, 1000);
      })
      .catch((err) => {
        console.log("err", err);
        setLoading(false);
        uploadFileFailed();
        onCancelfn();
      });
  };

  const uploadingFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      setFile(event.target.files[0]);
      setFileName(event.target.files[0].name);
    }
  };

  const uploadFileSuccess = () => {
    messageApi.success(t("File Upload Success!"), 2.5);
  };

  const uploadFileFailed = () => {
    messageApi.error(t("File Upload Failed!"), 2.5);
  };

  return (
    <>
      {contextHolder}
      <Modal
        open={modalState}
        onCancel={() => onCancelfn()}
        className="google-upload-modal"
        footer={null}
        centered
      >
        <div className="file-upload">
          <div className="file-upload-container">
            <div>
              {t("File Name")}: &nbsp;
              <input
                id="file-upload"
                type="text"
                onChange={(e) => setFileName(e.target.value)}
              />
            </div>
            <input type="file" onChange={uploadingFile} />
          </div>
          {fileName && (
            <Button
              type="primary"
              disabled={file == undefined}
              onClick={() => {
                setLoading(true);
                messageApi.loading({
                  content: t("Uploading..."),
                  duration: 2.5,
                });
                uploadToGoogle(fileName);
              }}
            >
              <img src={`./images/GoogleDriveBtn/btn.png`} width={14} />
              {t("Upload to Google Drive")}
            </Button>
          )}
        </div>
      </Modal>
    </>
  );
};

export default FileUploadModal;
