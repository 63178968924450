import "../../../assets/scss/google-cloud-download-modal-style.scss";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Button, Modal, Spin } from "antd";
import {
  BranchesOutlined,
  DesktopOutlined,
  LoadingOutlined,
  RobotOutlined,
} from "@ant-design/icons";

import { getDockerFileList } from "../../server/GoogleDrive";
import { getGPUInstnace } from "../../server/GPU";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { InstanceCardInterface } from "../../utility/interface/instance-card";
import {
  updateDockerList,
  updatedSelectedDocker,
} from "../../features/cloud/cloudSlice";
import { getGoogleDriveState } from "../../utility/common/session-store";

const InstanceSelectModal = (props: any) => {
  const { t } = useTranslation();
  const { modalState, switchtoDockerListFn, onCancelfn } = props;
  const userState = useAppSelector((state: any) => state.user);
  const [loading, setLoading] = useState<boolean>(false);
  const [instancesList, setInstancesList] = useState<InstanceCardInterface[]>(
    []
  );

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const fetchInstanceList = async () => {
    const response = await getGPUInstnace(userState.user_id);
    const data = await response.data;
    setLoading(false);
    if (data.gpu_instance !== null) {
      setInstancesList(data.gpu_instance.reverse());
    } else {
      setInstancesList([]);
    }
  };

  const getDockerFiles = async (instance_id: string, machine_id: string) => {
    setLoading(true);
    let treatedState = getGoogleDriveState();
    getDockerFileList(treatedState!, {
      command: "0",
      new_file_name: "",
      folder: "",
      search: "",
      file: "",
      instance_id: instance_id,
      machine_id: machine_id,
      user_id: userState.user_id,
    })
      .then((res) => {
        dispatch(updateDockerList(res.data.file_result));
        dispatch(
          updatedSelectedDocker({
            user_id: userState.user_id,
            instance_id: instance_id,
            machine_id: machine_id,
          })
        );
        setLoading(false);
        onCancelfn();
        switchtoDockerListFn();
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  useEffect(() => {
    if (userState.user_id) {
      setLoading(true);
      fetchInstanceList();
    }
  }, [useState]);

  return (
    <Modal
      open={modalState}
      onCancel={() => onCancelfn()}
      width={"auto"}
      className="google-cloud-download-modal"
      footer={null}
      closable={!loading}
      centered
    >
      {instancesList.length === 0 && loading == false && (
        <div className="no-instances">
          <p>{t("No Machines Yet")}</p>
          <Button onClick={() => navigate("/")}>{t("Go to Rental")}</Button>
        </div>
      )}
      {loading === true ? (
        <div
          style={{
            display: "flex",
            alignItems: "middle",
            justifyContent: "center",
          }}
        >
          <Spin indicator={<LoadingOutlined spin style={{ fontSize: 72 }} />} />
        </div>
      ) : (
        <div className="file-cloud-download">
          {instancesList.map((item: any, index: any) => {
            return (
              <div
                key={index}
                className="instance-item"
                onClick={() => {
                  getDockerFiles(item.instance_id, item.machine_id);
                }}
              >
                <li>
                  <DesktopOutlined /> ID: {item.instance_id}
                </li>
                <li>
                  <RobotOutlined /> {t("Machine")}: {item.product_name}
                </li>
                <li>
                  <BranchesOutlined /> {t("GPU Count")}: {item.gpu_count} GPU
                </li>
                <strong>{t("Choose This Instance")}</strong>
              </div>
            );
          })}
        </div>
      )}
    </Modal>
  );
};

export default InstanceSelectModal;
