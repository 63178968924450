import { stringify } from "qs";
import axiosClient from "../axios";
import { ResultDataModel } from "../../utility/interface/api/result-data";

interface GetFileListReq {
  page_size?: number;
  search_query?: string;
  page_token?: string;
  order_by?: string;
}
interface GetDockerFileListReq {
  command: string;
  new_file_name: string;
  folder: string;
  search: string;
  file: string;
  instance_id: string;
  machine_id: string;
  user_id: string;
}

interface DownloadFromDriveReq {
  file_id: string;
  destination_path: string;
}
interface DownloadToDriveReq {
  file_id: string;
  destination_path: string;
  instance_id: string;
  machine_id: string;
  user_id: string;
}

interface DockerToDriveReq {
  file_path: string;
  new_file_name: string;
  instance_id: string;
  machine_id: string;
  user_id: string;
}

export const getGoogleDriveOAuth = (cloudRoute: string) => {
  const url = `getGoogleDriveOAuth?urlName=${cloudRoute}`;
  return axiosClient.get<ResultDataModel<any>>(url);
};

export const getFilesListFromGoogleDrive = (
  state: string,
  params: GetFileListReq
) => {
  const url = `getFilesListFromGoogleDrive?page_size=${params.page_size}&page_token=${params.page_token}&order_by=folder&search_query=${params.search_query}`;
  const headers = {
    headers: {
      state,
    },
  };
  return axiosClient.get<ResultDataModel<any>>(url, headers);
};

export const downloadFromGoogleDrive = (
  state: string,
  params: DownloadFromDriveReq
) => {
  const url = `downloadFromGoogleDrive?file_id=${params.file_id}&destination_path=${params.destination_path}`;
  return axiosClient.get<ResultDataModel<any>>(url, {
    responseType: "blob",
    headers: {
      state,
    },
  });
};

export const uploadToGoogleDrive = (state: string, params: FormData) => {
  const url = `uploadToGoogleDrive`;
  const headers = {
    headers: {
      state,
      "Content-Type": "multipart/form-data",
    },
  };
  return axiosClient.post<ResultDataModel<any>>(url, params, headers);
};

export const downloadDriveToDocker = (
  state: string,
  params: DownloadToDriveReq
) => {
  const url = `downloadGDriveToDocker`;
  const formData = new FormData();
  // 添加所有參數到FormData
  formData.append("file_id", params.file_id);
  formData.append("destination_path", params.destination_path);
  formData.append("user_id", params.user_id);
  formData.append("machine_id", params.machine_id);
  formData.append("instance_id", params.instance_id);

  const headers = {
    headers: {
      state,
    },
  };

  return axiosClient.post<ResultDataModel<any>>(url, formData, headers);
};

export const uploadDockerToGoogleDrive = (
  state: string,
  params: DockerToDriveReq
) => {
  const url = `uploadDockerToGDrive`;
  const headers = {
    headers: {
      state,
    },
  };
  const formData = new FormData();
  // 添加所有參數到FormData
  formData.append("file_path", params.file_path);
  formData.append("user_id", params.user_id);
  formData.append("machine_id", params.machine_id);
  formData.append("instance_id", params.instance_id);
  formData.append("new_file_name", params.new_file_name);

  return axiosClient.post<ResultDataModel<any>>(url, formData, headers);
};

// Docker list operation fn

export const getDockerFileList = (
  state: string,
  params: GetDockerFileListReq
) => {
  const url = `getFileListOnBindMount`;
  const formData = new FormData();
  formData.append("command", params.command);
  formData.append("new_file_name", params.new_file_name);
  formData.append("folder", params.folder);
  formData.append("search", params.search);
  formData.append("file", params.file);
  formData.append("user_id", params.user_id);
  formData.append("machine_id", params.machine_id);
  formData.append("instance_id", params.instance_id);

  const config = {
    headers: {
      state,
    },
  };

  return axiosClient.post<ResultDataModel<any>>(url, formData, config);
};
