import axios from "axios";
import { errorInterceptor, updateHeaderInterceptor } from "./Interceptor";

declare module "axios" {
  export interface AxiosInstance {
    request<T = any>(config: AxiosRequestConfig): Promise<T>;
    get<T = any>(url: string, config?: AxiosRequestConfig): Promise<T>;
    delete<T = any>(url: string, config?: AxiosRequestConfig): Promise<T>;
    head<T = any>(url: string, config?: AxiosRequestConfig): Promise<T>;
    post<T = any>(
      url: string,
      data?: any,
      config?: AxiosRequestConfig
    ): Promise<T>;
    put<T = any>(
      url: string,
      data?: any,
      config?: AxiosRequestConfig
    ): Promise<T>;
    patch<T = any>(
      url: string,
      data?: any,
      config?: AxiosRequestConfig
    ): Promise<T>;
  }
}

const axiosClient = axios.create({
  headers: {
    "ngrok-skip-browser-warning": "69420",
  },
  baseURL: process.env.REACT_APP_API_BASE_URL,
  // withCredentials: true,
  paramsSerializer: (param) => {
    let res = encodeURIComponent(param).replaceAll("%27", "'");
    res.replaceAll("%20", " ");
    return res;
  }, // TODO: see if this has a work around, for google search
});

updateHeaderInterceptor(axiosClient);
errorInterceptor(axiosClient);

export default axiosClient;
