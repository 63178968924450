import "../../../assets/scss/instance-card-style.scss";

import { Skeleton } from "antd";
import { GoogleDriveCard } from "../GoogleDriveCard";
import { useTranslation } from "react-i18next";
import CloudBreadcrumb from "../CloudBreadcrumb";
import { getGoogleDriveState } from "../../utility/common/session-store";

const GoogleCloudFileList = (props: any) => {
  const { t } = useTranslation();

  const {
    backtoBaseLvlFn,
    changeListFn,
    fileList,
    fileListLoading,
    folderStructure,
    getNextPageFilesFn,
    goToFolderBreadcrumbFn,
    uploadFileFn,
    nextPageToken,
  } = props;
  return (
    <>
      <h1>{t("File List")}</h1>
      <CloudBreadcrumb
        backtoBaseLvlFn={backtoBaseLvlFn}
        folderStructure={folderStructure}
        goToFolderBreadcrumbFn={goToFolderBreadcrumbFn}
      />
      <div className="file-list-header">
        <span key="0" className="file-name-column">
          {t("File Name")}
        </span>
        <span key="1" className="file-date-column">
          {t("Date Modified")}
        </span>
        <span key="2" className="file-date-column">
          {t("Date Created")}
        </span>
        <span key="3" className="file-date-column">
          {t("Size")}
        </span>
      </div>
      <div className="file-list">
        {fileList.map((item: any) => {
          return (
            <GoogleDriveCard
              id={item.id}
              key={item.id}
              name={item.name}
              createdTime={item.createdTime}
              modifiedTime={item.modifiedTime}
              mimeType={item.mimeType}
              size={item.size}
              userState={getGoogleDriveState()}
              uploadFile={uploadFileFn}
              changeList={changeListFn}
            />
          );
        })}
        {fileListLoading ? (
          <Skeleton paragraph={{ rows: 3, width: "100%" }} active />
        ) : (
          nextPageToken !== "" && (
            <div className="view-more-bar" onClick={() => getNextPageFilesFn()}>
              {t("View More Files")}
            </div>
          )
        )}
      </div>
    </>
  );
};

export default GoogleCloudFileList;
