import React, { useEffect } from "react";
import moment from "moment";

import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { ConfigProvider, Layout, theme } from "antd";

import { QuestionOutlined } from "@ant-design/icons";

import { Account } from "../layouts/account";
import { Billings } from "../layouts/billings";
import { Cloud } from "../layouts/cloud";
import { Dashboard } from "../layouts/dashboard";
import { Instances } from "../layouts/instances";
import { Templates } from "../layouts/templates";
import { Payment } from "../layouts/payment";
import { NotFound } from "../layouts/notFound";
import DownloadProgress from "../layouts/downloadProgress";

import { useAppSelector } from "../store/hooks";

import { LangComp } from "../components/LangComp";
import { MainMenu } from "../components/mainMenu";
import { ProviderMainMenu } from "../components/providerMainMenu";
import { InstanceDetail } from "../layouts/instanceDetail";
import { RentSummary } from "../layouts/rentSummary";
import { Installation } from "../layouts/installation";
import { MachineStatus } from "../layouts/machineStatus";
import { SessionFieldEnum } from "../utility/enum/session-field.enum";
import { Profits } from "../layouts/profits";
import DockerFileList from "../components/DockerFileList";

const { Header, Content, Footer } = Layout;

const Main: React.FC = () => {
  const { t } = useTranslation();
  const userState = useAppSelector((state: any) => state.user);
  const cloudState = useAppSelector((state: any) => state.cloud);
  const loggedIn = sessionStorage.getItem(SessionFieldEnum.AccessToken);
  const navigate = useNavigate();

  useEffect(() => {
    if (loggedIn === null || undefined) {
      navigate("/login");
    }
  }, []);

  return (
    <ConfigProvider
      theme={{
        hashed: false,
        token: {
          colorPrimary: "#1a1a1a",
        },
        components: {
          Layout: {
            siderBg: "#1a1a1a",
            triggerBg: "#1F263E",
          },
          Menu: {
            darkItemBg: "transparent",
            darkItemSelectedBg: "transparent",
          },
        },
      }}
    >
      <Layout className="main-wrapper">
        {userState.is_provider ? <ProviderMainMenu /> : <MainMenu />}
        <Layout className="layout">
          <Header className="header-wrapper">
            <div className="tutorial-btn">
              <a href={"./pdf/platform_tutorial.pdf"} target="_blank">
                <QuestionOutlined />
              </a>
            </div>
            <LangComp />
          </Header>
          <Content className="content">
            {userState.profile.email === "" && <Navigate to="login" />}
            {userState.is_provider ? (
              <Routes>
                <Route path={`/installation`} element={<Installation />} />
                <Route path={`/profits`} element={<Profits />} />
                <Route path={`/machineStatus`} element={<MachineStatus />} />
              </Routes>
            ) : (
              <Routes>
                <Route path={`/`} element={<Dashboard />} />
                <Route path={`/account`} element={<Account />} />
                <Route path={`/billings`} element={<Billings />} />
                <Route path={`/cloud`} element={<Cloud />} />
                <Route
                  path={"/downloadProgress"}
                  element={<DownloadProgress />}
                />
                <Route path={"instances"} element={<Instances />} />
                <Route
                  path={"/dockerlist"}
                  element={
                    <DockerFileList dockerList={cloudState.dockerList} />
                  }
                />
                <Route path={"/instances/*"} element={<InstanceDetail />} />
                <Route path={`/rentSummary`} element={<RentSummary />} />
                <Route path={`/templates`} element={<Templates />} />
                <Route path={`/payment`} element={<Payment />} />
                <Route path={"/404"} element={<NotFound />} />
                <Route path="*" element={<Navigate to="/404" />} />
              </Routes>
            )}
          </Content>
          <Footer style={{ textAlign: "center" }}>
            NeuroWatt ©{moment().year()}
          </Footer>
        </Layout>
      </Layout>
    </ConfigProvider>
  );
};

export default Main;
