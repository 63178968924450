import "../../../assets/scss/google-drive-card-style.scss";
import { useEffect, useRef, useState } from "react";
import { message } from "antd";
import { useTranslation } from "react-i18next";
import {
  CloudDownloadOutlined,
  CloudServerOutlined,
  DownloadOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import moment from "moment";

import { GoogleDriveCardInterface } from "../../utility/interface/google-drive-card";
import { useAppDispatch, useAppSelector } from "../../store/hooks";

import {
  downloadFromGoogleDrive,
  getFilesListFromGoogleDrive,
} from "../../server/GoogleDrive";

import {
  updateCurrList,
  updateNextPageToken,
  goDeeperIntoFolder,
} from "../../features/cloud/cloudSlice";
import CloudDownloadMdoal from "../CloudDownloadMdoal";
import LoadingModal from "../LoadingModal";
import { useNavigate } from "react-router-dom";
import { setGoogleDriveState } from "../../utility/common/session-store";

export const GoogleDriveCard = (props: GoogleDriveCardInterface) => {
  const { t } = useTranslation();
  const {
    id,
    name,
    mimeType,
    size,
    modifiedTime,
    createdTime,
    userState,
    uploadFile,
    changeList,
  } = props;
  const [treatedState, setTreatedState] = useState<string>("");
  const [cloudDownloadModal, setCloudDownloadModal] = useState<boolean>(false);
  const [menu, setMenu] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [menuStyle, setMenuStyle] = useState<object>({});
  const [messageApi, contextHolder] = message.useMessage();
  const componentRef = useRef<any>(null);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const user_id = useAppSelector((state: any) => state.user.user_id);

  const downloadLoading = () => {
    messageApi.loading({
      content: t("Downloading..."),
      duration: 2.5,
    });
  };

  const downloadSuccess = () => {
    messageApi.success({
      content: t("Download Success!"),
    });
  };

  const downloadError = () => {
    messageApi.error({
      content: t("Download Failed!"),
    });
  };

  const getFileType = (mimeType: string, filename: string) => {
    if (mimeType.includes("folder")) {
      return <img src={`./images/GoogleDriveBtn/folder.png`} />;
    } else if (
      mimeType.includes("presentation") ||
      mimeType.includes("keynote")
    ) {
      return <img src={`./images/GoogleDriveBtn/presentation.png`} />;
    } else if (mimeType.includes("spreadsheet")) {
      return <img src={`./images/GoogleDriveBtn/sheets.png`} />;
    } else if (
      mimeType.includes("document") ||
      mimeType.includes("pdf") ||
      mimeType.includes("text")
    ) {
      return <img src={`./images/GoogleDriveBtn/document.png`} />;
    } else if (mimeType.includes("image")) {
      return <img src={`./images/GoogleDriveBtn/image.png`} />;
    } else if (mimeType.includes("zip")) {
      let arr = filename.split(".");
      let item = arr[arr.length - 1];
      return fileExt(item);
    }
  };

  const fileExt = (filename: string) => {
    if (filename.includes("zip")) {
      // return <img src={`./images/GoogleDriveBtn/folder.png`} />;
    } else if (filename.includes("ppt") || filename.includes("pptx")) {
      return <img src={`./images/GoogleDriveBtn/presentation.png`} />;
    } else if (filename.includes("xlsx")) {
      return <img src={`./images/GoogleDriveBtn/sheets.png`} />;
    } else if (
      filename.includes("doc") ||
      filename.includes("docx") ||
      filename.includes("txt")
    ) {
      return <img src={`./images/GoogleDriveBtn/document.png`} />;
    } else if (
      filename.includes("jpg") ||
      filename.includes("png") ||
      filename.includes("gif")
    ) {
      return <img src={`./images/GoogleDriveBtn/image.png`} />;
    } else if (filename.includes("zip")) {
      return <img src={`./images/GoogleDriveBtn/zip.png`} />;
    }
  };

  const treatSize = (size: number) => {
    let treatedSize = 0;
    treatedSize = Math.round(Number(size / 1000));
    if (treatedSize <= 1000) {
      return `${treatedSize} KB`;
    } else {
      let nextSize = Math.round(Number(treatedSize / 1000));
      return `${nextSize} MB`;
    }
  };

  const getFilesFromFolder = async (parent: string) => {
    getFilesListFromGoogleDrive(treatedState!, {
      page_size: 20,
      page_token: "",
      search_query: "'" + parent + "' in parents",
    })
      .then((res: any) => {
        dispatch(updateCurrList(res.data.files));
        dispatch(updateNextPageToken(res.data.next_page_token));
        setLoading(false);
      })
      .catch((err: any) => {
        setLoading(false);
        navigate("/cloud");
      });
  };

  const goToFolder = () => {
    setLoading(true);
    getFilesFromFolder(id);
    dispatch(goDeeperIntoFolder([{ name: name, route: id }]));
  };

  const downloadFileToLocal = () => {
    setMenu(false);
    downloadLoading();
    downloadFromGoogleDrive(treatedState, {
      file_id: id,
      destination_path: "",
    })
      .then((res: any) => {
        // Create a Blob from the response data
        const fileBlob = new Blob([res.data], {
          type: res.headers["content-type"],
        });

        // Create a temporary URL for the Blob
        const url = window.URL.createObjectURL(fileBlob);

        // Create a temporary <a> element to trigger the download
        const tempLink = document.createElement("a");
        tempLink.href = url;
        tempLink.setAttribute("download", `${name}`); // Set the desired filename for the downloaded file

        // Append the <a> element to the body and click it to trigger the download
        document.body.appendChild(tempLink);
        tempLink.click();

        // Clean up the temporary elements and URL
        document.body.removeChild(tempLink);
        window.URL.revokeObjectURL(url);
        downloadSuccess();
      })
      .catch((err) => {
        console.log(err);
        downloadError();
      });
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      componentRef.current &&
      !componentRef.current.contains(event.target as Node)
    ) {
      setMenu(false);
    }
  };

  useEffect(() => {
    if (userState !== null || undefined) {
      let ts = userState.split("|user_id")[0];
      setTreatedState(ts);
      setGoogleDriveState(ts);
    }
  }, [userState]);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <>
      {contextHolder}
      <div
        key={id}
        style={{
          position: "relative",
          cursor: "pointer",
        }}
        className="file-list-item"
        onContextMenu={(e) => {
          e.preventDefault(); // prevent the default behaviour when right clicked
          if (mimeType?.includes("folder")) {
            return;
          } else {
            let rect = e.currentTarget.getBoundingClientRect();
            let x = e.clientX - rect.left;
            let y = e.clientY - rect.top;
            setMenuStyle({ top: y, left: x });
            setMenu(!menu);
          }
        }}
        onClick={() => {
          mimeType?.includes("folder") && goToFolder();
        }}
      >
        <h3 className="file-name-column">
          {getFileType(mimeType!, name!)}
          {name}
        </h3>
        <span className="file-date-column">
          {moment(modifiedTime).format("YYYY-MM-DD")}
        </span>
        <span className="file-date-column">
          {moment(createdTime).format("YYYY-MM-DD")}
        </span>
        <span className="file-date-column">
          {size !== 0 ? treatSize(size!) : "-"}
        </span>
        {menu && (
          <ul
            ref={componentRef}
            className="right-click-menu-drive"
            style={menuStyle}
          >
            <li onClick={() => downloadFileToLocal()}>
              <DownloadOutlined /> {t("Download")}
            </li>
            <li onClick={() => setCloudDownloadModal(true)}>
              <CloudDownloadOutlined />
              {t("Download to Docker")}
            </li>
            <li onClick={() => uploadFile()}>
              <UploadOutlined />
              {t("Upload to Google Drive")}
            </li>
            <li onClick={() => changeList()}>
              <CloudServerOutlined />
              {t("Upload From Docker to Google Drive")}
            </li>
          </ul>
        )}
      </div>
      {cloudDownloadModal && (
        <CloudDownloadMdoal
          state={treatedState}
          file_id={id}
          modalState={cloudDownloadModal}
          onCancelfn={() => setCloudDownloadModal(false)}
          user_id={user_id}
        />
      )}
      {loading && <LoadingModal />}
    </>
  );
};
