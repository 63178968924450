import { Modal, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

const LoadingModal = () => {
  return (
    <Modal open={true} closable={false} centered footer={null} width={"auto"}>
      <Spin indicator={<LoadingOutlined spin style={{ fontSize: 72 }} />} />
    </Modal>
  );
};

export default LoadingModal;
