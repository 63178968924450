import "../../../assets/scss/instance-card-style.scss";
import { useTranslation } from "react-i18next";
import { chooseInstance } from "../../features/instance/instanceSlice";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { InstanceCardInterface } from "../../utility/interface/instance-card";

import {
  BranchesOutlined,
  DesktopOutlined,
  HistoryOutlined,
  MoneyCollectOutlined,
  RobotOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { calculateTime } from "../../utility/common/common";
import moment from "moment";
import { getDockerFileList } from "../../server/GoogleDrive";
import {
  updateDockerList,
  updatedSelectedDocker,
} from "../../features/cloud/cloudSlice";
import {
  clearGoogleDriveState,
  getGoogleDriveState,
} from "../../utility/common/session-store";

export const InstanceCard = (props: InstanceCardInterface) => {
  const { t } = useTranslation();
  const userState = useAppSelector((state: any) => state.user);
  const settingsState = useAppSelector((state: any) => state.settings);
  const {
    idx,
    instance_id,
    machine_id,
    time_consumed,
    price,
    gpu_count,
    product_name,
    service_url,
    ssh_url,
    public_key,
    rent_all,
  } = props;
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const selectInstance = () => {
    getDockerFiles(instance_id.toString(), machine_id.toString());
    dispatch(
      chooseInstance({
        instance_id: instance_id,
        machine_id: machine_id,
        price: price,
        gpu_count: gpu_count,
        service_url: service_url,
        ssh_url: ssh_url,
        time_consumed: time_consumed,
        user_id: userState.user_id,
        product_name: product_name,
        public_key: public_key,
        rent_all: rent_all,
      })
    );
    navigate(`/instances/${instance_id}`);
  };

  const getDockerFiles = async (instance_id: string, machine_id: string) => {
    let treatedState = getGoogleDriveState();
    getDockerFileList(treatedState!, {
      command: "0",
      new_file_name: "",
      folder: "",
      search: "",
      file: "",
      instance_id: instance_id,
      machine_id: machine_id,
      user_id: userState.user_id,
    })
      .then((res) => {
        dispatch(updateDockerList(res.data.file_result));
        dispatch(
          updatedSelectedDocker({
            user_id: userState.user_id,
            instance_id: instance_id,
            machine_id: machine_id,
          })
        );
      })
      .catch((err) => {
        console.log(err);
        clearGoogleDriveState();
      });
  };

  const calculateTotal = (
    unit_price: number,
    min: number,
    gpu_count: number
  ) => {
    let total = 0;
    total = unit_price * min * gpu_count;
    let newTotal = total.toFixed(2);
    return newTotal;
  };

  return (
    <>
      <div className="instance-card" onClick={() => selectInstance()}>
        <ul className="instance-card-details">
          {idx === 0 && <li className="new-tag">new</li>}
          <li>
            <DesktopOutlined /> ID: {instance_id}
          </li>
          <li>
            <RobotOutlined /> {t("Machine")} ID: {machine_id}
          </li>
          <li>
            <BranchesOutlined /> {t("GPU Count")}: {gpu_count} GPU
          </li>
          <li>
            <HistoryOutlined /> {t("Time Used")}:{" "}
            {calculateTime(time_consumed, settingsState.lang)}
          </li>
          <li>
            <MoneyCollectOutlined /> {t("Total Amount")}: USD $
            {calculateTotal(price, Number(time_consumed) / 3600, gpu_count!)}
            {settingsState.lang !== "en" && "元"}
          </li>
        </ul>
      </div>
    </>
  );
};
