import React, { useState, useEffect } from "react";
import { Progress } from "antd";
import { Card } from "antd";
import { Button } from "antd";
// import { Download } from "lucide-react";

const DownloadProgress = (props: any) => {
  const downloadUrl = "http://localhost:2700/events";
  const [progress, setProgress] = useState(0);
  const [status, setStatus] = useState("idle");
  const startDownload = () => {
    setStatus("downloading");
    setProgress(0);
    // 建立 SSE 連接
    const eventSource = new EventSource(downloadUrl);
    // 監聽進度更新
    eventSource.onmessage = (event) => {
      const data = JSON.parse(event.data);
      setProgress(data.percent);
      // 當進度到達 100% 時關閉連接
      if (data.percent >= 100) {
        eventSource.close();
        setStatus("completed");
      }
    };
    // 錯誤處理
    eventSource.onerror = (error) => {
      console.error("SSE Error:", error);
      eventSource.close();
      setStatus("error");
    };
  };
  // 組件卸載時清理 SSE 連接
  useEffect(() => {
    return () => {
      if (status === "downloading") {
        const eventSource = new EventSource(downloadUrl);
        eventSource.close();
      }
    };
  }, [status, downloadUrl]);
  return (
    <Card className="w-full max-w-md">
      <div className="flex items-center gap-2">
        {/* <Download className="h-5 w-5" /> */}
        文件下載
      </div>
      <div>
        <div className="space-y-4">
          <Progress percent={progress} className="w-full" />
          <div className="text-sm text-gray-500">
            {status === "idle" && "準備下載..."}
            {status === "downloading" && `下載中 ${progress}%`}
            {status === "completed" && "下載完成"}
            {status === "error" && "下載出錯"}
          </div>
          <Button
            onClick={startDownload}
            disabled={status === "downloading"}
            className="w-full"
          >
            {status === "idle" ? "開始下載" : "重新下載"}
          </Button>
        </div>
      </div>
    </Card>
  );
};
export default DownloadProgress;
