import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Modal, message } from "antd";
import { FolderAddOutlined } from "@ant-design/icons";

import {
  updateDockerList,
  updateFolderPath,
} from "../../features/cloud/cloudSlice";
import { getDockerFileList } from "../../server/GoogleDrive";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { clearGoogleDriveState } from "../../utility/common/session-store";
import { useTranslation } from "react-i18next";

const DockerCreateFolder = () => {
  const { t } = useTranslation();
  const [folderName, setFolderName] = useState<string>("");
  const [createModal, setCreateModal] = useState<boolean>(false);
  const userState = useAppSelector((state: any) => state.user);
  const selectedDocker = useAppSelector(
    (state: any) => state.cloud.selectedDocker
  );
  const [messageApi, contextHolder] = message.useMessage();

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const createDockerFolder = () => {
    getDockerFileList("", {
      command: "4",
      new_file_name: "",
      folder: folderName,
      search: "",
      file: "",
      instance_id: selectedDocker.instance_id,
      machine_id: selectedDocker.machine_id,
      user_id: userState.user_id,
    })
      .then((res) => {
        dispatch(updateDockerList(res.data.file_result));
        setFolderName("");
        createFolderSuccess();
      })
      .catch((err) => {
        console.log(err);
        setFolderName("");
        createFolderFailed();
        clearGoogleDriveState();
        dispatch(updateFolderPath(""));
        navigate("/cloud");
      });
  };

  const treatFolderName = (name: string) => {
    let treatedName = name.replace(/[&\/\\#,+()$~%.'":;*?<>{}]/g, "_");
    setFolderName(treatedName);
  };

  const closeFolderModal = () => {
    setCreateModal(false);
    setFolderName("");
  };

  const createFolderSuccess = () => {
    messageApi.success(t("Create Folder Success!"), 2.5);
    setCreateModal(false);
  };

  const createFolderFailed = () => {
    messageApi.error(t("Create Folder Failed!"), 2.5);
    setCreateModal(false);
  };

  return (
    <>
      {contextHolder}
      <div className="floating-btn" onClick={() => setCreateModal(true)}>
        <FolderAddOutlined />
      </div>
      <Modal
        open={createModal}
        title={t("Create Folder")}
        cancelText={t("cancel")}
        okText={t("Create Folder")}
        onCancel={() => closeFolderModal()}
        onOk={() => createDockerFolder()}
        okButtonProps={{ disabled: folderName === "" ? true : false }}
        centered
      >
        <span>{t("New Folder Name")}: &nbsp;</span>
        <input
          id="file-upload"
          type="text"
          value={folderName}
          onChange={(e) => treatFolderName(e.target.value)}
        />
      </Modal>
    </>
  );
};

export default DockerCreateFolder;
