import "../../../assets/scss/google-cloud-docker-file-list-style.scss";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { message } from "antd";
import {
  CloudDownloadOutlined,
  DeleteOutlined,
  FolderOpenOutlined,
} from "@ant-design/icons";
import {
  getDockerFileList,
  uploadDockerToGoogleDrive,
} from "../../server/GoogleDrive";
import { useAppDispatch, useAppSelector } from "../../store/hooks";

import {
  updateCurrDockerFolderName,
  updateDockerList,
  updateFolderPath,
} from "../../features/cloud/cloudSlice";
import {
  clearGoogleDriveState,
  getGoogleDriveState,
} from "../../utility/common/session-store";

const DockerItem = (props: any) => {
  const { t } = useTranslation();
  const { index, state, file } = props;
  const [menu, setMenu] = useState<boolean>(false);
  const [menuStyle, setMenuStyle] = useState<object>({});
  const [treatedState, setTreatedState] = useState<any>("");
  const [newFileName, setNewFileName] = useState<string>("");
  const [messageApi, contextHolder] = message.useMessage();
  const componentRef = useRef<any>(null);

  const userState = useAppSelector((state: any) => state.user);
  const selectedDocker = useAppSelector(
    (state: any) => state.cloud.selectedDocker
  );
  const folderPath = useAppSelector((state: any) => state.cloud.folderPath);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const uploadToGoogleDrive = (origFile: string, newFileName: string) => {
    setMenu(false);
    downloadLoading();
    uploadDockerToGoogleDrive(treatedState!, {
      new_file_name: newFileName,
      file_path: `/home/gveda/user_data/${selectedDocker.instance_id}/${folderPath}${origFile}`,
      instance_id: selectedDocker.instance_id,
      machine_id: selectedDocker.machine_id,
      user_id: userState.user_id,
    })
      .then((res) => {
        if (res.data.code === 0) {
          downloadSuccess();
        }
      })
      .catch((err) => {
        downloadError();
        if (getGoogleDriveState() == "") {
          stateError();
        }
      });
  };

  const deleteItem = (name: string, folder: boolean) => {
    setMenu(false);
    deleteLoading();
    getDockerFileList(treatedState!, {
      command: folder ? "5" : "6",
      new_file_name: "",
      folder: folder ? name : "",
      search: "",
      file: folder ? "" : name,
      instance_id: selectedDocker.instance_id,
      machine_id: selectedDocker.machine_id,
      user_id: userState.user_id,
    })
      .then((res) => {
        dispatch(updateDockerList(res.data.file_result));
        deleteSuccess();
      })
      .catch((err) => {
        deleteError();
        if (getGoogleDriveState() == "") {
          stateError();
        }
      });
  };

  const enterFolder = (name: string) => {
    setMenu(false);
    enterLoading();
    getDockerFileList(treatedState!, {
      command: "1",
      new_file_name: "",
      folder: name,
      search: "",
      file: "",
      instance_id: selectedDocker.instance_id,
      machine_id: selectedDocker.machine_id,
      user_id: userState.user_id,
    })
      .then((res) => {
        const treatedPath = folderPath.endsWith("/")
          ? folderPath + name + "/"
          : folderPath + "/" + name + "/"; // Remove trailing slash if it exists

        dispatch(updateDockerList(res.data.file_result));
        dispatch(updateFolderPath(treatedPath));
        dispatch(updateFolderPath(treatedPath));
        dispatch(updateCurrDockerFolderName(name));
        enterSuccess();
      })
      .catch((err) => {
        enterError();
        clearGoogleDriveState();
        dispatch(updateCurrDockerFolderName(""));
        dispatch(updateFolderPath(""));
        navigate("/cloud");
      });
  };

  const treatSize = (size: number) => {
    let treatedSize = 0;
    treatedSize = Math.round(Number(size / 1000));
    if (treatedSize <= 1000) {
      return `${treatedSize} KB`;
    } else {
      let nextSize = Math.round(Number(treatedSize / 1000));
      return `${nextSize} MB`;
    }
  };

  // context items

  const downloadLoading = () => {
    messageApi.loading(t("Downloading..."), 1.5);
  };

  const downloadSuccess = () => {
    messageApi
      .loading(t("Download Success!"), 1.5)
      .then(() => window.location.reload());
  };

  const downloadError = () => {
    messageApi.error(t("Download Failed!"));
  };

  const deleteLoading = () => {
    messageApi.loading(t("Deleting..."), 1.5);
  };

  const deleteSuccess = () => {
    messageApi.success(t("Delete Success!"), 1.5);
  };

  const deleteError = () => {
    messageApi.error(t("Delete Failed!"));
  };

  const enterLoading = () => {
    messageApi.loading(t("Loading..."), 1.5);
  };

  const enterSuccess = () => {
    messageApi.success(t("Loading Success!"), 1.5);
  };

  const enterError = () => {
    messageApi.error(t("Loading Failed!"));
  };

  const stateError = () => {
    messageApi.error(t("Please connect to Google Drive!"));
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      componentRef.current &&
      !componentRef.current.contains(event.target as Node)
    ) {
      setMenu(false);
    }
  };

  useEffect(() => {
    if (state == "" || state == null || undefined) {
      let newState = getGoogleDriveState();
      setTreatedState(newState);
    } else {
      setTreatedState(state);
    }

    if (folderPath !== "" && !folderPath.includes("/")) {
      dispatch(updateFolderPath(""));
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <>
      {contextHolder}
      <div
        key={index}
        className="row file-list-item"
        style={{
          position: "relative",
          cursor: file.permissions === "-rw-rw-r--" ? "default" : "pointer",
        }}
        onContextMenu={(e) => {
          e.preventDefault(); // prevent the default behaviour when right clicked
          let rect = e.currentTarget.getBoundingClientRect();
          let x = e.clientX - rect.left;
          let y = e.clientY - rect.top;
          setMenuStyle({ top: y, left: x });
          setMenu(!menu);
        }}
      >
        <span className="column">
          {file.permissions === "-rw-rw-r--" ? (
            <img
              width={"20px"}
              style={{ marginRight: ".5em" }}
              src={`./images/GoogleDriveBtn/file.png`}
            />
          ) : (
            <img
              width={"20px"}
              style={{ marginRight: ".5em" }}
              src={`./images/GoogleDriveBtn/folder.png`}
            />
          )}{" "}
          {file.name}
        </span>
        <span className="column">{file.date}</span>
        <span className="column">
          {file.size !== 0 ? treatSize(file.size) : "-"}
        </span>
        {menu && (
          <ul
            ref={componentRef}
            className="right-click-menu-drive"
            style={menuStyle}
          >
            {file.permissions === "-rw-rw-r--" ? (
              <>
                <li onClick={() => uploadToGoogleDrive(file.name, newFileName)}>
                  <CloudDownloadOutlined />
                  {t("Download to Google Drive")}
                </li>
                <li
                  onClick={() =>
                    deleteItem(file.name, file.permissions === "-rw-rw-r--")
                  }
                >
                  <DeleteOutlined />
                  {t("Delete")}
                </li>
              </>
            ) : (
              <>
                <li onClick={() => enterFolder(file.name)}>
                  <FolderOpenOutlined />
                  {t("Enter Folder")}
                </li>
                <li
                  onClick={() =>
                    deleteItem(file.name, file.permissions !== "-rw-rw-r--")
                  }
                >
                  <DeleteOutlined />
                  {t("Delete Folder")}
                </li>
              </>
            )}
          </ul>
        )}
      </div>
    </>
  );
};

export default DockerItem;
