import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../store/hooks";

import "../../../assets/scss/rental-card-style.scss";
import { RentalCardInterface } from "../../utility/interface/rental-card";

import { Button, Tag } from "antd";
import {
  ApiOutlined,
  ArrowUpOutlined,
  ArrowDownOutlined,
  BulbOutlined,
  CoffeeOutlined,
  DatabaseOutlined,
  DesktopOutlined,
  RobotOutlined,
  SaveOutlined,
} from "@ant-design/icons";

import { chooseGPUSelectionInfo } from "../../features/selection/selectionSlice";

export const RentalCard = (props: RentalCardInterface) => {
  const { t } = useTranslation();
  const {
    gpu_ram,
    gpu_count,
    total_gpu_count,
    machine_id,
    product_name,
    price,
    effective_ram,
    cpu_name,
    deep_learning_performance_score,
    disk_max_available_storage,
    internet_upload_speed,
    internet_download_speed,
    teraflops,
  } = props;
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const chooseGPU = (selectedAsWhole: boolean) => {
    dispatch(
      chooseGPUSelectionInfo({
        gpu_ram,
        gpu_count,
        total_gpu_count,
        machine_id,
        product_name,
        price,
        effective_ram,
        cpu_name,
        deep_learning_performance_score,
        disk_max_available_storage,
        internet_upload_speed,
        internet_download_speed,
        teraflops,
        selected_as_whole: selectedAsWhole,
      })
    );
    navigate("/templates");
  };

  return (
    <>
      <div className="rental-card">
        <div className="rental-card-specs">
          <div className="title">
            <h3>{product_name}</h3>
            {(machine_id === 111112 ||
              machine_id === 123145 ||
              machine_id === 123142) && (
              <span>
                <img width={"80px"} src={`/images/services/homee-ai.png`} />
              </span>
            )}
          </div>
          <div className="price">
            ${price}
            <span> /GPU * {t("hours")}</span>
          </div>
          <Tag color={"processing"} bordered={false}>
            <strong>{gpu_count}</strong> {t("GPUs Available")}
          </Tag>
          &nbsp;
          <Tag color={"error"} bordered={false}>
            <strong>{Number(total_gpu_count! - gpu_count!)}</strong>{" "}
            {t("GPUs Already In Used")}
          </Tag>
        </div>
        <ul className="rental-card-details">
          <li className="speed-wrapper">
            <span className="speed-title">
              <ApiOutlined />
              &nbsp;{t("Internet Speed")}:
            </span>
            <div className="speed">
              <span>
                <ArrowUpOutlined /> {internet_upload_speed} Mbps
              </span>
              <span>
                <ArrowDownOutlined /> {internet_download_speed} Mbps
              </span>
            </div>
          </li>
          <li>
            <DesktopOutlined /> {t("GPU RAM")}: {gpu_ram} GB
          </li>
          <li>
            <RobotOutlined /> CPU: {cpu_name}
          </li>
          <li>
            <SaveOutlined /> {t("Effective RAM")}: {effective_ram} GB
          </li>
          <li>
            <DatabaseOutlined /> {t("Disk Storage")}:{" "}
            {disk_max_available_storage} GB
          </li>
          <li>
            <BulbOutlined /> DL Perf: {deep_learning_performance_score} DLP
          </li>
          <li>
            <CoffeeOutlined /> Total GPU TeraFLOPS: {teraflops}
          </li>
        </ul>
        <Button
          type="default"
          disabled={
            gpu_count === 0 || Number(total_gpu_count! - gpu_count!) !== 0
          }
          onClick={() => chooseGPU(true)}
        >
          {t("Rent All Available GPUs")}
        </Button>
        <br />
        <Button
          type="primary"
          disabled={gpu_count === 0}
          onClick={() => chooseGPU(false)}
        >
          {t("Rent")}
        </Button>
      </div>
    </>
  );
};
