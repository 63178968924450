import "../../assets/scss/login-style.scss";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { message, Row, Col } from "antd";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from "yup";

import { LangComp } from "../components/LangComp";
import { changeProviderPwd } from "../server/Provider";

const InitializeProvider: React.FC = () => {
  const { t } = useTranslation();
  const [messageApi, contextHolder] = message.useMessage();

  const navigate = useNavigate();

  const ChangePwdSchema = Yup.object().shape({
    email: Yup.string()
      .email(t("Incorrect Email format"))
      .required(t("Please provide your Email")),
    old_password: Yup.string().required(t("Old Password field is required")),
    new_password: Yup.string().required(t("New Password field is required")),
    confirmNewPassword: Yup.string()
      .required(t("Confirm New Password field is required"))
      .oneOf(
        [Yup.ref("new_password")],
        t("Please make sure the passwords match")
      ),
  });

  const changePwdSuccess = () => {
    messageApi.success({
      content: t("Password changed success!"),
    });
  };

  const changePwdError = () => {
    messageApi.error({
      content: t("Password changed failed!"),
    });
  };

  return (
    <>
      {contextHolder}
      <div className="login-page">
        <main className="login-container">
          <div className="login-card">
            <div className="login-card-left">
              <div className="logo" />
            </div>
            <div className="login-card-right initialize">
              <div className="right-side-initialize-top">
                <h1>{t("Welcome to NeuroWatt!")}</h1>
                <h3>
                  {t(
                    "Please change your password in order to activate your account"
                  )}
                </h3>
              </div>
              <div className="right-side-middle">
                <div className="form">
                  <Formik
                    validateOnMount={true}
                    enableReinitialize={true}
                    validationSchema={ChangePwdSchema}
                    initialValues={{}}
                    onSubmit={(values: any) => {
                      changeProviderPwd(
                        values.email,
                        values.new_password,
                        values.old_password
                      )
                        .then((res) => {
                          if (res.data.code === 0) {
                            changePwdSuccess();
                            setTimeout(() => {
                              navigate("/login");
                            }, 1000);
                          }
                          if (res.data.code === 1) {
                            changePwdError();
                          }
                        })
                        .catch((res) => {
                          console.log(res);
                          changePwdError();
                        });
                    }}
                  >
                    {({ errors, touched }) => (
                      <Form>
                        <Row gutter={[0, 24]}>
                          <Col span={24}>
                            <div className="label-wrapper">
                              <label htmlFor="email">{t("E-Mail")}</label>
                              <Field name="email" type="email" />
                              <div className="form-error-msg">
                                <ErrorMessage name="email" />
                              </div>
                            </div>
                          </Col>
                        </Row>
                        <Row gutter={[0, 24]}>
                          <Col span={24}>
                            <div className="label-wrapper">
                              <label htmlFor="old_password">
                                {t("Old Password")}
                              </label>
                              <Field name="old_password" type="password" />
                              <div className="form-error-msg">
                                <ErrorMessage name="old_password" />
                              </div>
                            </div>
                          </Col>
                        </Row>
                        <Row gutter={[0, 12]}>
                          <Col span={11}>
                            <div className="label-wrapper">
                              <label htmlFor="new_password">
                                {t("New Password")}
                              </label>
                              <Field name="new_password" type="password" />
                              <div className="form-error-msg">
                                <ErrorMessage name="new_password" />
                              </div>
                            </div>
                          </Col>
                          <Col span={11}>
                            <div className="label-wrapper">
                              <label htmlFor="confirmNewPassword">
                                {t("Confirm New Password")}
                              </label>
                              <Field
                                name="confirmNewPassword"
                                type="password"
                              />
                              <div className="form-error-msg">
                                <ErrorMessage name="confirmNewPassword" />
                              </div>
                            </div>
                          </Col>
                        </Row>
                        <button className="sign-in-btn" type="submit">
                          {t("Update Password")}
                        </button>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
              <div className="right-side-bottom">
                <footer>
                  <span>
                    {t("Language Selected")}: <LangComp type="text" />
                  </span>
                  <div className="logo-black" />
                  <span>Copyright © 2024 NeuroWatt. All rights reserved.</span>
                </footer>
              </div>
            </div>
          </div>
        </main>
      </div>
    </>
  );
};

export default InitializeProvider;
